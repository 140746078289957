@use "@swisspost/design-system-styles/variables/grid" with ($grid-gutter-width: 1rem);


@use "@swisspost/design-system-styles";

@use "styles/index";

.w-0 {
    width: 0;
    flex: 0 0 0%;
    max-width: 0;
}