@use "@swisspost/design-system-styles/core" as post;

// Prevent main page scrolling while detail overlay is open
.prevent-scrolling {
  height: 100vh;
  overflow-y: scroll;

  body {
    height: 100vh;
    overflow: hidden;
  }

  @supports(overscroll-behavior: contain) {
    height: initial;
    overflow-y: initial;

    body {
      height: initial;
      overflow: initial;
    }
  }
}