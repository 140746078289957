// Reset calendar styles
ngb-datepicker.dropdown-menu {
  max-width: none;
  max-height: none;
}

ngb-typeahead-window.dropdown-menu {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
}


input[type="checkbox"].form-check-input {
  &:focus ~ label {
    &:before {
      box-shadow: 0 0 0 0.2rem rgba(51, 51, 51, 0.25);
    }
  }
}

// Srsly WTF!
// https://stackoverflow.com/questions/3773430/remove-outline-from-select-box-in-ff
select:-moz-focusring {
  color: transparent !important;
  text-shadow: 0 0 0 #000 !important;
}

input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}

select,
.custom-select {
  padding-right: 2rem;
}