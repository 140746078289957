@use "@swisspost/design-system-styles/core" as post;

.font-curve-small {
    @include post.media-breakpoint-up(md) {
        font-weight: 300;
    }
}

.font-curve-regular {
    font-weight: 300;
}

.font-size-17 {
    font-size: 1.0625rem;
}

.font-size-15 {
    font-size: 0.9375rem;
}

h1, h2, h3,
h4, h5, h6 {
    margin-top: 0;
}