@use "@swisspost/design-system-styles/core" as post;

$colored-icons: 3164, 2168;

@each $icon in $colored-icons {
    .pi-#{$icon}-success {
        @include post.pi($icon, post.$green);
        border-color: post.$green;
    }
    
    .pi-#{$icon}-danger {
        @include post.pi($icon, post.$red);
        border-color: post.$red;
    }
    
    .pi-#{$icon}-gray-600 {
        @include post.pi($icon, post.$gray-600);
        border-color: post.$gray-600;
    }
}
