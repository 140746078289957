@use "@swisspost/design-system-styles/core" as post;

@include post.media-breakpoint-down(sm) {
  .accordion {
    .card-header {
      .btn {
        font-weight: bold;
        padding-right: 2.5rem;
        padding-left: 0;
      }
    }
  
    .card {
      .card-body {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
}

::part(accordion-item) {
  --post-bg-rgb: white;
  border-top: 2px solid post.$gray-10;
  border-bottom: unset;
}