@use "@swisspost/design-system-styles/core" as post;

app-date-slider {
  .flickity-prev-next-button {
    top: 0;
    height: 100%;
    transform: none;
    width: 2.5rem;
    border-radius: 0;
    transition: opacity 150ms;

    &:active {
      opacity: 1;
    }

    &:disabled {
      opacity: 0;
    }

    &.previous {
      left: 0;
      background: linear-gradient(90deg, white 1.5rem, transparent);
      padding-right: 1rem;
    }

    &.next {
      right: 0;
      background: linear-gradient(-90deg, white 1.5rem, transparent);
      padding-left: 1rem;
    }

    .flickity-button-icon {
      position: static;
      width: auto;
      height: auto;
    }
  }
}