@use "@swisspost/design-system-styles/core" as post;

$internet-header-height-mobile: 56px;
$internet-header-height-desktop: 72px;

.u_mod_skiplinks {
  display: none;
}

.header-visible {
  // [class] gives one free specificity to override scoping on components
  .result-count-mobile[class] {
    @supports (position: sticky) {
      top: $internet-header-height-mobile;
      transition: top 200ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .map-wrapper.is-sticky[class] {
    .search-info[class] {
      top: calc(2rem + #{$internet-header-height-mobile});
      transition: top 200ms cubic-bezier(0, 0, 0.2, 1);
    }
  }

  .results.is-sticky[class] {
    > app-result-filter {
      @supports (position: sticky) {
        transition: top 200ms cubic-bezier(0, 0, 0.2, 1);
        top: $internet-header-height-mobile;

        @include post.media-breakpoint-up(lg) {
          top: 64px;
        }

        @include post.media-breakpoint-up(xl) {
          top: $internet-header-height-desktop;
        }
      }
    }
  }
}
