:root{
  --pulse-size: 0px;
}

.current-location-div{ 
  border-style: none;
  border-width: 0px;
  position: absolute;
}

.current-location-image{ 
  position: absolute;
  top: -16px;
  left: -16px;
}

.pulse {
  position: absolute;
  margin-top: calc(var(--pulse-size) / -2);
  margin-left: calc(var(--pulse-size) / -2);
}

.pulse::after {
  display: block;
  width: var(--pulse-size);
  height: var(--pulse-size);
  content: '';
  animation: pulsate 1.5s ease-out;
  animation-iteration-count: infinite;
  opacity: 0;
  border-radius: 50%;
  box-shadow: 0 0 6px 3px steelblue;
}

@keyframes pulsate {
  0% {
      transform: scale(.1, .1);
      opacity: 0;
  }
  50% {
      opacity: 1;
  }
  100% {
      transform: scale(1, 1);
      opacity: 0;
  }
}