@use "@swisspost/design-system-styles/core" as post;

html {
  overflow-x: hidden;
}

video {
  max-width: 100%;
}

.pointer {
  cursor: pointer;
}

.bg-image-unset {
  background-image: unset;
}

// Color names:
// https://post.frontify.com/document/2307#/grundlagen-web/farben
// Text colors overwritten after design review
.text-confirmation {
  color: #255E1C;
}

.text-danger {
  color: #8F0D1C !important;
}

// Strip top and bottom margin from first and last child when creating boxed content 
// which should not have bleeding margins into the next context box
.box {
  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }
}

.text-gray-600 {
  color: post.$gray-600;
}

@mixin hidden() {
  display: none !important;
}

.hidden {
  @include hidden;
}

.toast-info {
  background-color: post.$yellow !important;
}

.alert-info {
  background-color: post.$yellow !important;
}

@include post.media-breakpoint-down(sm) {
  .alert {
      background-image: unset;
      padding-top: post.$size-small-large;
  }
}

@include post.media-breakpoint-up(rg) {
  .hidden-rg {
    @include hidden;
  }
}

@include post.media-breakpoint-up(md) {
  .hidden-md-up {
    @include hidden;
  }
}

@include post.media-breakpoint-down(md) {
  .hidden-md-down {
    @include hidden;
  }
}

.map-wrapper {
  [tabindex] {
    outline: none;
  }
}