#toposmap {
  .gm-style-iw-chr {
    > button {
      display: none !important;
    }
  }

  .gm-style-iw-c {
    border-radius: 0;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }
}